<template>
  <div class="inputText" :class="width">
    <FormKit
      :id="id"
      v-model="value"
      type="password"
      :placeholder="t(placeholder)"
      :name="name ?? id"
      :input-class="{
        input: true,
        [inputClass]: inputClass,
      }"
      :outer-class="'mb-sm'"
      validation-visibility="blur"
      :validation-messages="{
        required: t('formFields.password.emptyError'),
      }"
      :validation="required ? 'required' : ''"
      label-class="text-md !leading-[19px] font-bold"
      :inner-class="{
        'pt-[5px] relative': true,
        'opacity-50': disabled,
        [innerClass]: innerClass,
      }"
      :messages-class="'!bg-minilu-bg !rounded-br-minilu-md !rounded-bl-minilu-md px-sm py-[2px] text-minilu-red'"
      :disabled="disabled"
      :data-testid="testid"
    >
      <template #label>
        <div class="flex justify-between">
          <FormLabel
            v-if="!$slots.label && label"
            :label="label"
            :required="required"
          />
          <slot v-if="$slots.label" name="label" />
          <div
            v-if="hasForgotPassword"
            class="cursor-pointer text-minilu-base"
            @click="$emit('clickForgot')"
          >
            {{ t('formFields.password.forgotPassword') }}
          </div>
        </div>
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import { FormLabel } from '@/complib';
import { FormKit } from '@formkit/vue';

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: false,
    default: undefined,
  },
  required: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: 'formFields.password.placeholder',
  },
  name: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: 'w-full',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  inputClass: {
    type: String,
    default: '',
  },
  innerClass: {
    type: String,
    default: '',
  },
  hasForgotPassword: {
    type: Boolean,
    default: true,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: false,
  },
  testid: {
    type: String,
    default: '',
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'clickForgot'): void;
}>();

const value = ref<string>(props.modelValue);

onMounted(() => {
  const input: HTMLElement = document.querySelector(
    `input[name='${props.name}']`,
  );
  if (props.autofocus) {
    input.focus();
  }
});

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue;
  },
);

watch(value, (newValue) => {
  emit('update:modelValue', newValue);
});

const { t } = useTrans();
</script>
<style lang="postcss" scoped>
.inputText :deep(input.input) {
  @apply border-l-1 border-r-1 border-t-1 outline-0 border-b-[3px] h-9 px-2 md:px-4 pt-2 text-md text-minilu-font ring-0 focus:border-b-[3px] shadow-none z-100;
  @apply focus:border-b-minilu-base-light rounded-[6px] border-minilu-border-light;
  @apply w-full;
}

.inputText :deep([data-invalid='true']) {
  input.input {
    @apply !border-b-minilu-red rounded-br-none rounded-bl-none;
  }
}
</style>
